import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import NewsScreen from "../screens/news"

const NewsPage = ({ data }) => (
  <>
    <SEO
      title="News"
    />
    <NewsScreen
      news={data}
    />
  </>
)

export default NewsPage

export const query = graphql`
  query AllNews {
    allNews {
      edges {
        node {
          id
          slug
          title
          date
          cover
          excerpt
          content
        }
      }
    }
  }
`
